import React from "react";
import Home from "./home/Home";

export const AppHome: React.FC = () => {
    return (
        <div>
            <Home />
        </div>
    );
};

export const AngeliaHome: React.FC = () => {
    return (
        <div>
            <h1>This is Angelia home</h1>
        </div>
    );
};