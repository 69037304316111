import './App.css';
import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getApp } from './utils/helpers';
import { AppHome, AngeliaHome } from './pages';
import About from './About';
import Nav from './Nav';

const App: React.FC = () => {
  const CurrentApp = getApp();
  return (
      <div className="App">
        <BrowserRouter>
          <Nav />
          <div>
          <Routes>
            <Route path="/" element={<CurrentApp />} />
            <Route path="/" element={<About />} />
            <Route path="/" element={<AngeliaHome />} />
          </Routes>
          </div>
        </BrowserRouter>
      </div>
  );
}

export default App
