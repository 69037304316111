import React from 'react';

const About = () => {
  return (
    <div>
        <h1>This is about Seed of Chaos</h1>
    </div>
  )
}

export default About;