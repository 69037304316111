import React from 'react';

const Home = () => {
  return (
    <div>
        <h1>This is the home page for Seed of Chaos</h1>
    </div>
  )
}

export default Home;